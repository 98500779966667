import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "removeField", "filename"]

  connect() {
    console.log("FileInput controller connected")
  }

  updateFileName(event) {
    const file = event.target.files[0]
    if (file) {
      const parent = this.element.parentElement
      const existingText = parent.querySelector('span')
      if (existingText) {
        existingText.textContent = file.name
      } else {
        const wrapper = document.createElement('div')
        wrapper.className = 'flex items-center space-x-2'
        wrapper.innerHTML = `
          <span class="text-sm text-gray-600">${file.name}</span>
          <button type="button"
                  class="text-sm text-blue-600 hover:text-blue-800"
                  data-action="click->file-input#triggerFileInput">
            Change
          </button>
        `
        this.element.classList.add('hidden')
        parent.appendChild(wrapper)
      }
    }
  }

  triggerFileInput() {
    this.inputTarget.click()
  }

  removeImage(event) {
    event.preventDefault()
    if (confirm(event.currentTarget.dataset.confirm)) {
      // Find and clear the file input
      this.inputTarget.value = ""

      // Set the remove flag
      const removeField = this.element.querySelector('input[name*="[remove_hero_image]"]')
      if (removeField) {
        removeField.value = "1"
      }

      // Show the file input
      this.inputTarget.classList.remove('hidden')

      // Remove the filename and buttons
      if (this.hasFilenameTarget) {
        this.filenameTarget.remove()
      }
    }
  }
}
